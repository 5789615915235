import React from "react"

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>えらぶむに ©{new Date().getFullYear()}. Photo: ARISA KASAI</p>
    </div>
  </footer>
)

export default Footer
