import React from "react"
import { Link } from "gatsby"

const Logo = (props) => (
  <div className="site-logo">
    <Link to="/">
		<img src="/assets/erabumuni_logo2.gif" alt=""/>
	</Link>
  </div>
)

export default Logo
